const virtualList = require("virtual-list");
const Swiper = require("swiper");

function whichTransitionEvent() {
  var t,
    el = document.createElement("fake"),
    transitions = {
      transition: "transitionend",
      OTransition: "oTransitionEnd",
      MozTransition: "transitionend",
      WebkitTransition: "webkitTransitionEnd",
    };
  for (t in transitions) if (el.style[t] !== undefined) return transitions[t];
}

/*
$( document ).on( 'cycle-initialized', function( e, opts ) {
	var clsImg = 'cycle-img-added'
	
	function addImg( slide ) {
		if( !slide.classList.contains( clsImg ) ) {
			slide.classList.add( clsImg )
			insertImg( slide, slide.dataset.url, app.var.auth )
		}
	}

	function removeImg( slide ) {
		$( slide )
			.removeClass( clsImg )
			.find( 'img' ).remove()
	}
	
	if( opts.slides[0] )
		addImg( opts.slides[0] )
	if( opts.slides[1] )
		addImg( opts.slides[1] )
	
	opts.container
		.on( 'cycle-before', function( e, opts, from, to, fwd ) {
			add = $( [from,to] )
			add = add.add( $( to ).next() )
			add = add.add( $( to ).prev() )
			add.addClass( 'cycle-exclude' )
			remove = $( '.' + clsImg ).not( '.cycle-exclude' )
			remove.toArray().forEach( removeImg )
			add.toArray().forEach( addImg )
			$( '.cycle-exclude' ).removeClass( 'cycle-exclude' )
		} )
		.on( 'page-next', function() {
			opts._tempFx = opts.swipeFx
			opts.API.next()
		} )
		.on( 'page-prev', function() {
			opts._tempFx = opts.swipeFx
			opts.API.prev()
		} )
		.on( 'page-first', function() {
			opts.API.jump( 0, opts.swipeFx )
			opts.API.trigger('cycle-prev', [ opts ]).log('cycle-first')
		} )
		.on( 'page-last', function() {
			opts.API.jump( opts.slides.length-1, opts.swipeFx )
			opts.API.trigger('cycle-next', [ opts ]).log('cycle-last')
		} )
		.on( 'cycle-update-view', function( e, opts, slideOpt, currentEl ) {
			//console.log( e, opts, slideOpt)
		} )
	
} )

$( document ).on( 'cycle-destroyed', function( e, opts ) {
	opts.container.off( 'page-next page-prev page-first page-last' )
} )

$( document ).on( 'keydown', function( e ) {
	if( $( '#reader ' ).hasClass( 'open' ) ) {
		switch( e.keyCode ) {
		case 37: case 33:
			app.pagePrev()
			break
		case 39: case 34:
			app.pageNext()
			break
		case 36:
			app.pageFirst()
			break
		case 35:
			app.pageLast()
			break
		case 27: case 38:
			app.closeReader()
			break
		//default: console.log( e.keyCode )
		}
	}
} )
*/

window.app = {
  var: {
    api: "https://bd.avnl.fr/api/bd/v2/",
    filtered: false,
    user: null,
    pwd: null,
    auth: null,
    transitionEnd: whichTransitionEvent(),
    transition: "transform 0.5s ease-out",
    bd: { pages: 0, page: 0 },
    vlseries: null,
  },
  ui: {
    series: document.querySelector("#library .series"),
    albums: document.querySelector("#library .albums"),
    detail: document.querySelector("#library .detail"),
    search: document.querySelector('#library input[type="search"]'),
    auteur: document.querySelector("#library .filters .auteur"),
    editeur: document.querySelector("#library .filters .editeur"),
    collection: document.querySelector("#library .filters .collection"),
    genre: document.querySelector("#library .filters .genre"),
    lecture: document.querySelector("#library .filters .lecture"),
    unfilter: document.querySelector("#library .unfilter"),
    statsRecherche: document.querySelector("#library footer .stats-recherche"),
    reindex: document.querySelector("#library footer .reindex"),
    reader: document.querySelector("#reader"),
    pages: document.querySelector("#reader section"),
    prev: document.querySelector("#reader .prev"),
    next: document.querySelector("#reader .next"),
    zoom: document.querySelector("#reader .zoom"),
    close: document.querySelector("#reader .close"),
    connection: document.querySelector("#connection"),
    connectionForm: document.querySelector("#connection form"),
    user: document.querySelector('#connection [name="user"]'),
    pwd: document.querySelector('#connection [name="pwd"]'),
    connectionBtn: document.querySelector("#connection button"),
    connectionMsg: document.querySelector("#connection .message"),
    lumiereBtn: document.querySelector(".lumiere"),
    lumiere: document.querySelector("#lumiere"),
    fullscreenBtn: document.querySelector(".fullscreen"),
  },

  tpl: {
    details: function (o) {
      var d = o.infos;
      app.ui.detail.classList.remove("loading");
      app.ui.detail.dataset.lecture = d.lecture === false ? "" : d.lecture;
      app.ui.detail.innerHTML += [
        '<div class="actions">',
        '<button class="lecture" data-id="',
        d.id_bd_album,
        '" title="Lu"><i class="fa fa-comment"></i></button>',
        //'<button class="jaime" data-id="',d.id_bd_album,'" title="Glop glop !"><i class="fa fa-thumbs-up"></i></button>',
        //'<button class="jaimepas" data-id="',d.id_bd_album,'" title="Pas glop, pas glop"><i class="fa fa-thumbs-down"></i></button>',
        //'<button class="alire" data-id="',d.id_bd_album,'" title="A lire"><i class="fa fa-bookmark"></i></button>',
        "</div>",
      ].join("");
      if (d.Notes != "")
        app.ui.detail.innerHTML +=
          '<div class="src">' +
          d.Notes.replace(/^(Bedetheque)[\s\S]+(20\d{2})[\s\S]+$/g, "$1 $2") +
          "</div>";
      app.ui.detail.innerHTML += [
        '<div class="cover">',
        '<img src="',
        app.var.api,
        "couverture/",
        d.id_bd_album,
        '" class="loading circle-dark" />',
        '<div class="open-reader"></div>',
        "</div>",
      ].join("");
      var num = "";
      if (d.Number != null || d.Count != null) {
        if (d.Number != null && d.Number == null) {
          num = "(" + d.Number + ")";
        } else {
          if (d.Number == null && d.Count != null) {
            num = "(?/" + d.Count + ")";
          } else {
            if (d.Number != null && d.Count != null) {
              num = "(" + d.Number + "/" + d.Count + ")";
            }
          }
        }
      }
      document.querySelector(".cover div").dataset.resolution = d.resolution;
      app.ui.detail.innerHTML +=
        '<h3><a class="filter" href="#">' + d.Series + "</a> " + num + "</h3>";
      app.ui.detail.innerHTML += "<h1>" + d.Title + "</h1>";
      var table = "";
      if (d.auteurs.Writer != null) {
        for (var i = 0, j = d.auteurs.Writer.length; i < j; i++)
          d.auteurs.Writer[i] =
            '<a href="#" class="filter" data-filter="auteur">' +
            d.auteurs.Writer[i] +
            "</a>";
        table +=
          "<tr><th>Scénario</th><td>" +
          d.auteurs.Writer.join(", ") +
          "</td></th>";
      }
      if (d.auteurs.Penciller != null) {
        for (var i = 0, j = d.auteurs.Penciller.length; i < j; i++)
          d.auteurs.Penciller[i] =
            '<a href="#" class="filter" data-filter="auteur">' +
            d.auteurs.Penciller[i] +
            "</a>";
        table +=
          "<tr><th>Dessin</th><td>" +
          d.auteurs.Penciller.join(", ") +
          "</td></th>";
      }
      if (d.auteurs.Colorist != null)
        table +=
          "<tr><th>Couleur</th><td>" +
          d.auteurs.Colorist.join(", ") +
          "</td></th>";
      if (d.Publisher != null)
        table +=
          '<tr><th>Editeur</th><td><a href="#" class="filter" data-filter="editeur">' +
          d.Publisher +
          "</a></td></th>";
      if (d.Imprint != null)
        table +=
          '<tr><th>Collection</th><td><a href="#" class="filter" data-filter="collection">' +
          d.Imprint +
          "</a></td></th>";
      if (d.Year != null)
        table +=
          "<tr><th>Dépôt</th><td>" + d.Month + "/" + d.Year + "</td></th>";
      if (d.genres != null) {
        for (var i = 0, j = d.genres.length; i < j; i++)
          d.genres[i] =
            '<a href="#" class="filter" data-filter="genre">' +
            d.genres[i] +
            "</a>";
        table += "<tr><th>Genre</th><td>" + d.genres.join(", ") + "</td></th>";
      }
      if (d.PageCount != null)
        table += "<tr><th>Pages</th><td>" + d.PageCount + "</td></th>";
      if (d.Format != null)
        table += "<tr><th>Format</th><td>" + d.Format + "</td></th>";
      if (d.CommunityRating != null)
        table += "<tr><th>Note</th><td>" + d.CommunityRating + "/5</td></th>";
      if (d.size != null)
        table +=
          "<tr><th>Taille</th><td>" + readableFileSize(d.size) + "</td></th>";
      app.ui.detail.innerHTML += "<table>" + table + "</table>";
      if (d.Summary != null)
        app.ui.detail.innerHTML +=
          "<p><strong>Résumé</strong><br>" + d.Summary + "</p>";
      if (d.Notes != "" && d.web != "")
        app.ui.detail.innerHTML +=
          '<p style="font-size:0.58em;"><strong>Source</strong> <a href="' +
          d.Web +
          '" target="_blank">' +
          d.Notes +
          "</a></p>";
      if (d.links && d.links.linked && d.links.linked.length) {
        let html = ''
        for (const item of d.links.linked) {
          html += `<a class="filter" href="#">${item}</a><br>`;
        }
        app.ui.detail.innerHTML += `<p><strong>Série reprenant l'univers ou les personnages </strong> :<br>${html}</p>`;
      }
      if (d.links && d.links.like.length) {
        let html = ''
        for (const item of d.links.like) {
          html += `<a class="filter" href="#">${item}</a><br>`;
        }
        app.ui.detail.innerHTML += `<p><strong>A lire aussi</strong> :<br>${html}</p>`;
      }
    },
    albums: function (o) {
      //console.log( o )
      return [
        "<a",
        ' data-id="',
        o.id_bd_album,
        '"',
        ' data-nb="',
        o.Number,
        '"',
        o.lecture === null ? "" : ' data-lecture="' + o.lecture + '"',
        ">",
        '<span class="cover" style="background-image:url(' +
          app.var.api +
          "couverture/",
        o.id_bd_album,
        ');"></span>',
        o.Title,
        "</a>",
      ].join("");
    },
    series: function (o) {
      return ['<a data-nb="', o.nb, '">', o.Series, "</a>"].join("");
    },
  },
  populate: function (d) {
    return new Promise(function (resolve) {
      var list = null,
        items = [];
      if (d.series) list = "series";
      if (d.albums) list = "albums";
      //console.log( 'populate '+list )
      if (list == null) Error("Erreur réseau");
      d[list].forEach(function (item) {
        items.push(app.tpl[list](item));
      });
      app.ui[list].classList.remove("loading");
      app.ui[list].innerHTML = items.join("");
      if (d.nbSeries && d.nbAlbums)
        app.ui.statsRecherche.innerHTML =
          d.nbSeries + " séries, " + d.nbAlbums + " albums";
      resolve();
    });
  },
  details: function () {
    return new Promise(function (resolve) {
      app.ui.detail.classList.add("loading");
      app.ui.detail.innerHTML = "";
      getJSON(
        app.var.api +
          "album/" +
          document.querySelector("#library .albums a.on").dataset.id,
        app.var.auth
      )
        .then(app.tpl.details)
        .then(resolve);
    });
  },
  listAlbums: function () {
    return new Promise(function (resolve) {
      //console.log( 'listAlbums' );
      var data = {},
        ui = app.ui,
        lecture = ui.lecture.value,
        qs = "";
      ui.albums.innerHTML = "";
      ui.detail.innerHTML = "";
      ui.albums.classList.add("loading");
      data = {
        Series: document.querySelector("#library .series a.on").text,
        q: ui.search.value,
      };
      if (lecture != "") data.lecture = lecture;
      qs = querystring(data);
      getJSON(app.var.api + "album?" + qs, app.var.auth)
        .then(app.populate)
        .then(resolve);
    });
  },
  listSeries: function (fullsearch) {
    return new Promise(function (resolve) {
      //console.log( 'listSeries' )
      var data = {},
        ui = app.ui,
        search = ui.search.value,
        auteur = ui.auteur.getAttribute("value") || "",
        editeur = ui.editeur.getAttribute("value") || "",
        collection = ui.collection.getAttribute("value") || "",
        genre = ui.genre.getAttribute("value") || "",
        lecture = ui.lecture.value,
        qs = "";
      app.var.filtered =
        search.length > 2 ||
        auteur != "" ||
        editeur != "" ||
        collection != "" ||
        genre != "" ||
        lecture != "";
      //ui.series.innerHTML = ''
      app.ui.series.items = [];
      ui.albums.innerHTML = "";
      ui.detail.innerHTML = "";
      ui.statsRecherche.innerHTML = "";
      ui.series.classList.add("loading");
      if (search != "") data.q = search;
      if (auteur != "") data.auteur = auteur;
      if (editeur != "") data.editeur = editeur;
      if (collection != "") data.collection = collection;
      if (genre != "") data.genre = genre;
      if (lecture != "") data.lecture = lecture;
      data.fullsearch = !!fullsearch;
      qs = querystring(data);
      app.ui.unfilter.disabled = !app.var.filtered;
      delete app.ui.detail.dataset.lecture;
      //getJSON( app.var.api + 'serie?' + qs ).then( app.populate ).then( resolve )
      getJSON(app.var.api + "serie?" + qs, app.var.auth)
        .then(function (d) {
          return new Promise(function (resolve) {
            if (!d.serie) Error("Erreur réseau");
            app.ui.series.classList.remove("loading");
            //app.ui[list].innerHTML = items.join( '' )
            app.var.vlseries.items = d.series;
            if (d.nbSeries && d.nbAlbums)
              app.ui.statsRecherche.innerHTML =
                d.nbSeries + " séries, " + d.nbAlbums + " albums";
            resolve();
          });
        })
        .then(resolve);
    });
  },
  searchHandler: function (e) {
    app.resetFiltres();
    const fullsearch = e.key === "Enter";
    // app.var.filtered =
    //   app.ui.search.value.length > 2 ||
    //   app.ui.auteur.value != "" ||
    //   app.ui.editeur.value != "" ||
    //   app.ui.collection.value != "" ||
    //   app.ui.genre.value != "" ||
    //   app.ui.lecture.value != "";
    if (app.ui.search.value.length > 2 || app.var.filtered == true)
      app.listSeries(fullsearch);
  },
  filtre: function (d, list, label) {
    var opt = [];
    opt.push('<optgroup label="' + label + '">');
    for (var i = 0, j = d[list].length; i < j; i++) {
      opt.push(
        '<option value="' +
          d[list][i].s +
          '">' +
          d[list][i].s +
          " (" +
          d[list][i].nb +
          ")</option>"
      );
    }
    opt.push("</optgroup>");
    return opt.join("");
  },
  filtres: function (d) {
    return new Promise(function (resolve) {
      // app.ui.auteur.innerHTML += app.filtre(
      //   d,
      //   "auteur-top",
      //   "Top " + d["auteur-top"].length
      // );
      // app.ui.auteur.innerHTML += app.filtre(
      //   d,
      //   "auteur",
      //   "Tous les auteurs (" + d["auteur"].length + ")"
      // );
      // app.ui.editeur.innerHTML += app.filtre(
      //   d,
      //   "editeur-top",
      //   "Top " + d["editeur-top"].length
      // );
      // app.ui.editeur.innerHTML += app.filtre(
      //   d,
      //   "editeur",
      //   "Tous les editeurs (" + d["editeur"].length + ")"
      // );
      // app.ui.collection.innerHTML += app.filtre(
      //   d,
      //   "collection-top",
      //   "Top " + d["collection-top"].length
      // );
      // app.ui.collection.innerHTML += app.filtre(
      //   d,
      //   "collection",
      //   "Toutes les collections (" + d["collection"].length + ")"
      // );
      // app.ui.genre.innerHTML += app.filtre(
      //   d,
      //   "genre-top",
      //   "Top " + d["genre-top"].length
      // );
      // app.ui.genre.innerHTML += app.filtre(
      //   d,
      //   "genre",
      //   "Tous les genres (" + d["genre"].length + ")"
      // );

      Object.keys(d.lecture).forEach(function (k) {
        app.ui.lecture.innerHTML +=
          '<option value="' + k + '">' + d.lecture[k] + "</option>";
      });

      resolve();
    });
  },
  resetFiltres: function () {
    app.ui.auteur.setAttribute("value", "");
    app.ui.editeur.setAttribute("value", "");
    app.ui.collection.setAttribute("value", "");
    app.ui.genre.setAttribute("value", "");
    app.ui.lecture.value = "";
  },
  page: function (slide, index) {
    // console.log( slide, index )
    return [
      '<div class="swiper-slide" data-url="',
      app.var.bd.url,
      app.var.bd.pages[index],
      '">',
      "</div>",
    ].join("");
  },
  getPages: function () {
    return new Promise(function (resolve) {
      var album = document.querySelector("#library .albums a.on").dataset.id;
      app.ui.reader.classList.add("loading");
      //app.var.bd.pages = [];
      getJSON(app.var.api + "page/" + album, app.var.auth).then(function (d) {
        app.var.bd.pages = d.index;
        app.var.bd.url = app.var.api + "page/" + album + "/";
        // app.ui.pages.innerHTML = d.index.map( function( page ) {
        // 	return app.page( album, page )
        // } ).join( '' )
        app.ui.reader.classList.remove("loading");
        resolve(d);
      });
    });
  },
  loadImages: function () {
    // console.log(this.activeIndex )
    const active = app.var.swiper.$wrapperEl[0].querySelector(
      ".swiper-slide-active"
    );
    if (active && active.dataset.url) {
      insertImg(active, active.dataset.url, app.var.auth);
      delete active.dataset.url;
    }
    const next =
      app.var.swiper.$wrapperEl[0].querySelector(".swiper-slide-next");
    if (next && next.dataset.url) {
      insertImg(next, next.dataset.url, app.var.auth);
      delete next.dataset.url;
    }
    const prev =
      app.var.swiper.$wrapperEl[0].querySelector(".swiper-slide-prev");
    if (prev && prev.dataset.url) {
      insertImg(prev, prev.dataset.url, app.var.auth);
      delete prev.dataset.url;
    }
    // console.log( prev,active,next )
  },
  openReader: function () {
    app.getPages().then(function (d) {
      //console.info( 'open reader' );
      app.var.swiper = new Swiper(".swiper-container", {
        slidesPerView: 1,
        keyboard: true,
        spaceBetween: 5,
        pagination: {
          el: ".numero-page",
          type: "fraction",
        },
        // navigation: {
        // 	nextEl: '.swiper-button-next',
        // 	prevEl: '.swiper-button-prev',
        // },
        virtual: {
          cache: false,
          slides: app.var.bd.pages,
          renderSlide: app.page,
        },
        init: false,
        on: {
          init: function () {
            if (d.page && d.page > 2)
              app.var.swiper.slideTo(d.page - 2, 0, false);
            app.var.swiper.virtual.update();
            app.loadImages();
            app.ui.reader.classList.add("open");
          },
          slideChangeTransitionEnd: app.loadImages,
        },
      });
      app.var.swiper.init();
    });
  },
  closeReader: function () {
    app.ui.reader.classList.remove("open");
    setTimeout(function () {
      app.var.swiper.virtual.removeAllSlides();
      app.var.swiper.destroy();
      delete app.var.swiper;
    }, 500);
  },
  // pagePrev: function () { $( app.ui.pages ).trigger( 'page-prev' ) },
  // pageNext: function () { $( app.ui.pages ).trigger( 'page-next' ) },
  // pageFirst: function() { $( app.ui.pages ).trigger( 'page-first' ) },
  // pageLast: function() { $( app.ui.pages ).trigger( 'page-last' ) },
  toggleZoom: function () {
    app.ui.reader.classList.toggle("contain");
  },
  connection: function (e) {
    if (e) e.preventDefault();
    if (app.ui.user.value !== "" && app.ui.pwd.value !== "") {
      app.var.auth = btoa(app.ui.user.value + ":" + app.ui.pwd.value);
      post(app.var.api + "login", app.var.auth)
        .then(app.connectionOk)
        .catch(app.connectionKo);
    }
  },
  connectionOk: function () {
    app.ui.connection.classList.remove("open");
    app.ui.connectionMsg.innerHTML = "Glop, glop !";
    setTimeout(function () {
      getJSON(app.var.api + "filtre", app.var.auth)
        .then(app.filtres)
        // .then(() => {
        //   app.ui.series.classList.remove("loading");
        // });
        .then(app.listSeries);
    }, 500);
  },
  connectionKo: function () {
    app.ui.connection.classList.add("open");
    app.ui.connectionMsg.innerHTML = "Pas glop, pas glop.";
  },
  disconnect: function () {
    app.ui.connectionMsg.innerHTML = "&nbsp;";
    app.ui.connection.classList.add("open");
  },
  reindex: function () {
    app.ui.statsRecherche.innerHTML =
      ' <i class="fa fa-spinner fa-pulse"></i> Indexation en cours...';
    put(app.var.api + "base", app.var.auth)
      .then(JSON.parse)
      .then(function (d) {
        if (d.series && d.albums)
          app.ui.statsRecherche.innerHTML =
            d.series + " séries, " + d.albums + " albums";
        app.listSeries();
      });
  },
  toggleLecture: function (id) {
    return new Promise(function (ok) {
      put(app.var.api + "lecture/" + id, app.var.auth)
        .then(JSON.parse)
        .then(function (d) {
          d = d === false ? "" : d;
          app.ui.albums.querySelector(
            'a[data-id="' + id + '"]'
          ).dataset.lecture = d;
          app.ui.detail.dataset.lecture = d;
          ok();
        });
    });
  },
  init: function () {
    app.ui.search.value = "";

    app.var.vlseries = new virtualList({
      el: app.ui.series,
      itemHeight: 35,
      item: function (index, data) {
        // console.log(data)
        return [
          '<a style="display:block; position:absolute; top:',
          index * this.itemHeight,
          "px; height:",
          this.itemHeight,
          'px; width:100%;" data-nb="',
          data[2],
          '">',
          data[0],
          "</a>",
        ].join("");
      },
    });

    //console.log( app.var.vlseries )

    app.ui.connectionForm.addEventListener("submit", app.connection, false);
    app.ui.connectionBtn.addEventListener("click", app.connection, false);
    app.ui.reindex.addEventListener("click", app.reindex, false);

    app.ui.albums.addEventListener("click", function (e) {
      if (e.target && e.target.nodeName == "A") {
        var o = document.querySelector("#library .albums a.on");
        if (o) o.classList.remove("on");
        e.target.classList.add("on");
        app.details();
      }
    });
    app.ui.series.addEventListener("click", function (e) {
      if (e.target && e.target.nodeName == "A") {
        var o = document.querySelector("#library .series a.on");
        if (o) o.classList.remove("on");
        e.target.classList.add("on");
        app.listAlbums().then(function () {
          var a = document.querySelector("#library .albums a");
          if (a) a.click();
        });
      }
    });
    app.ui.search.addEventListener(
      "keyup",
      debounce(app.searchHandler, 400),
      false
    );
    app.ui.auteur.addEventListener("change", app.listSeries, false);
    app.ui.editeur.addEventListener("change", app.listSeries, false);
    app.ui.collection.addEventListener("change", app.listSeries, false);
    app.ui.genre.addEventListener("change", app.listSeries, false);
    app.ui.lecture.addEventListener("change", app.listSeries, false);
    app.ui.unfilter.addEventListener("click", function () {
      app.ui.search.value = "";
      app.resetFiltres();
      app.listSeries();
    });

    // $( 'select' ).on( 'change', function() { $( this ).attr( 'value', $( this ).val() ) } )

    app.ui.detail.addEventListener(
      "click",
      function (e) {
        //console.log( e.target.classList[0] )
        switch (e.target.classList[0]) {
          case "open-reader":
            app.openReader();
            break;
          case "lecture":
            e.target.disabled = true;
            app.toggleLecture(e.target.dataset.id).then(function () {
              e.target.disabled = false;
            });
            break;
          case "jaime":
            break;
          case "jaimepas":
            break;
          case "alire":
            break;
          case "filter":
            if (e.target.dataset.filter) {
              // console.log(e.target);
              app.ui.search.value = "";
              app.resetFiltres();
              app.ui[e.target.dataset.filter].setAttribute(
                "value",
                e.target.textContent
              );
              app.listSeries(true);
            } else {
              app.ui.search.value = e.target.textContent;
              app.resetFiltres();
              app.listSeries(false).then(function () {
                var a = document.querySelector("#library .series a");
                if (a) a.click();
              });
            }
            break;
        }
      },
      false
    );

    // app.ui.prev.addEventListener( 'click', app.pagePrev, false )
    // app.ui.next.addEventListener( 'click', app.pageNext, false )
    app.ui.zoom.addEventListener("click", app.toggleZoom, false);
    app.ui.close.addEventListener("click", app.closeReader, false);

    app.ui.lumiereBtn.addEventListener("click", function () {
      app.ui.lumiere.classList.toggle("on");
    });

    app.ui.fullscreenBtn.addEventListener("click", function () {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    })
  },
};

// window.onload = app.init;
document.addEventListener("DOMContentLoaded", app.init);
